<template>
  <div
    class="
      small-plane
      border-radius-6
      box-shadow-light-grey
      padding-20
      height-calc-type1
    "
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style lang='scss' scoped>
.small-plane {
  background: #fff;
  // width: 1700px;
}
</style>